<template>
	<div class="register register_box" :key="pageKey">
		<!-- <my-img srcText="login/back.jpg" classText="video-img" /> -->
		<!-- <video class="video" id="register_video" preload="auto" loop="" autoplay="autoplay" muted>
			<source src="/assets/login/video.mp4" type="video/mp4" />
		</video> -->
		<div class="login_box">
			<div class="contentBox">
				<div class="logo">
					<a style="width:205px;height:69px">
						<my-img srcText="login/login_logo.svg" styleText="width:205px;height:69px;display: block;margin: 0  auto;" />
					</a>
				</div>
				<div class="logboxbbox">
					<div class="logboxbboxt">
				<div class="sponsor">
					<my-img class="sponsor-img" srcText="ckpnin43n5hfjcsj2tv0_610507.png" />
				<!-- 	<div class="title">贊助夥伴</div>
					<div class="sponsorBox">
						<div class="sponsorItem">
							<my-img srcText="login/zz-1.png" />
							<div class="sponsorInfo">
								<p class="sponsorInfoTop">皇家馬德裏</p>
								<p class="sponsorInfoBtm">Real Madrid</p>
							</div>
						</div>
						<div class="sponsorItem">
							<my-img srcText="login/zz-2.png" />
							<div class="sponsorInfo">
								<p class="sponsorInfoTop">比利時國家隊</p>
								<p class="sponsorInfoBtm">Royal Belgian FA</p>
							</div>
						</div>
						<div class="sponsorItem">
							<my-img srcText="login/zz-3.png" />
							<div class="sponsorInfo">
								<p class="sponsorInfoTop">亞特蘭大</p>
								<p class="sponsorInfoBtm">Atalanta B.C.</p>
							</div>
						</div>
					</div> -->
				</div>
					<ul class="login_input">
						<li class="row">
							<div class="userWrap" :class="{ has_error: nameIsError }">
								<i class="icon user"></i><input type="text" placeholder="請輸入註冊賬號" maxlength="32"
									name="name" v-model="name" @blur="nameCensor" @focus="nameChange" />
							</div>
							<p class="input_error" v-if="nameIsError">
								<my-img srcText="login/icon_wranning.png"
									styleText="width:12px;height:12px;margin-right:4px" />請輸入6-12位字母+數字的組合
							</p>
							<my-img srcText="login/ico-sign-check-1.png" classText="success-icon"
								v-if="nameIsSuccess" />
						</li>
						<li class="row">
							<div class="userWrap" :class="{ has_error: passwordIsError }">
									<i class="icon password"></i><input :type="isShowPassword ? 'text' : 'password'"
										placeholder="密碼由6-12位數字或字母組成" maxlength="32" autocomplete="new-password2"
										name="password" v-model="password" @blur="passwordCensor"
										@focus="passwordChange" />
									<div style="
                      position: absolute;
                      top: 13px;
                      right: 12px;
                      width: 16px;
                      height: 16px;
                      cursor: pointer;
                    " @click="isShowPassword = !isShowPassword">
										<my-img srcText="login/eye_close.png"
											styleText="display: block;width:16px;height:16px;" />
									</div>
							</div>
							<p class="input_error" v-if="passwordIsError">
								<my-img srcText="login/icon_wranning.png"
									styleText="width:12px;height:12px;margin-right:4px" />請輸入6-12位字母+數字的組合
							</p>
							<my-img srcText="login/ico-sign-check-1.png" classText="success-icon"
								v-if="passwordIsSuccess" />
						</li>
						<li class="row">
							<div class="userWrap" :class="{ has_error: passwordConfirmIsError }">
								
									<i class="icon password"></i><input placeholder="再次輸入密碼 *"
										:type="isShowPasswordConfirm ? 'text' : 'password'" maxlength="32"
										autocomplete="new-password2" name="password" v-model="passwordConfirm"
										@blur="passwordConfirmCensor" @focus="passwordConfirmChange" />
									<div style="
                      position: absolute;
                      top: 13px;
                      right: 12px;
                      width: 16px;
                      height: 16px;
                      cursor: pointer;
                    " @click="isShowPasswordConfirm = !isShowPasswordConfirm">
										<my-img srcText="login/eye_close.png"
											styleText="display: block;width:16px;height:16px;" />
									</div>
								
							</div>
							<p class="input_error" v-if="passwordConfirmIsError">
								<my-img srcText="login/icon_wranning.png"
									styleText="width:12px;height:12px;margin-right:4px" />
								兩次密碼不相同，請檢查
							</p>
							<my-img srcText="login/ico-sign-check-1.png" classText="success-icon"
								v-if="passwordConfirmIsSuccess" />

						</li>
						
						<li class="row">
							<div class="userWrap" :class="{ has_error: phoneIsError }">

								<i class="icon entryCode"></i><span style="
    margin-right:  -20px;
    width: 60px;
    line-height: 41px;
    color:  #fff;
    display:  inline-block; 
">+852</span><input type="text" placeholder="請輸入電話 *" maxlength="32" name="name" v-model="phone" @blur="phoneCensor"
									@focus="phoneChange" />
							</div>
							<p class="input_error" v-if="phoneIsError">
								<my-img srcText="login/icon_wranning.png"
									styleText="width:12px;height:12px;margin-right:4px" />請輸入合法的電話
							</p>
							<my-img srcText="login/ico-sign-check-1.png" classText="success-icon"
								v-if="phoneIsSuccess" />
						</li>
						<li class="row">
							<div class="userWrap" :class="{ has_error: codeIsError }">
								<i class="icon entryCode"></i><input type="text" placeholder="請輸入驗證碼" maxlength="10"
									autocomplete="off" name="validCode" v-model="code" @blur="codeCensor"
									@focus="codeChange" />
							</div>
							<p class="validImg" @click="getCode" style="
								border-left: 1px solid #fff;
								line-height:  30px;
								color: #fff;
							">{{getCodeText}}</p>
							<p class="input_error" v-if="codeIsError">
								<my-img srcText="login/icon_wranning.png"
									styleText="width:12px;height:12px;margin-right:4px" />
								請輸入6位數驗證碼
							</p>
							<my-img srcText="login/ico-sign-check-1.png" classText="success-icon"
								v-if="codeIsSuccess" />
						</li>
						<!-- <li class="row">
							<div class="userWrap" :class="{ has_error: nameIsError }">
								<i class="icon user22"></i><span style="    padding: 10px 55px 10px 0;margin-left: 18px;line-height: 20px;
    color:  #fff;">港幣</span>
							</div>
							<p class="input_error" v-if="nameIsError">
								<my-img srcText="login/icon_wranning.png"
									styleText="width:12px;height:12px;margin-right:4px" />4-11位，最少2个字母+数字组合，首位为字母
							</p>
							<my-img srcText="login/ico-sign-check-1.png" classText="success-icon"
								v-if="nameIsSuccess" />
						</li> -->
						<li class="row" v-show="false">
							<div class="userWrap">
								<i class="icon user"></i><input type="text" placeholder="請輸入註冊邀請碼" maxlength="32"
									name="name" v-model="iValue" />
							</div>
						</li>
						<li class="buttonBox">
							<button data-label="注册按钮点击次数" type="submit" class="submit" 
								 @click="register">
								
								<span>註 冊</span>
							</button>
						</li>
						<div class="folbnts">
							<div  class="folbnt1" @click="linkLogin">
								<span class="imm"></span>
								<span class="tese2">前往登錄</span>
							</div>
							<div  class="folbnt1" @click="home">
								<span class="imm"></span>
								<span class="tese2">遊客進入</span>
							</div>
							<div  class="folbnt1" @click="linkcs">
								<span class="imm"></span>
								<span class="tese2">聯系客服</span>
							</div>
						</div>
						<!-- <div class="linkGroup">
							<span>已有賬號？<span @click="linkLogin">請登錄</span></span><span @click="home">先去逛逛</span>
						</div> -->
					</ul>
					</div>
					</div>
			<!-- 	<div class="service">
					<my-img srcText="login/icon1.svg" classText="serviceIcon" styleText="width:29px;" /><span
						style="cursor: pointer">聯系客服</span>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import http from '../http.js'
	import {
		mapActions,
		mapGetters
	} from 'vuex';
	export default {
		name: "register",
		data() {
			return {
				name: "",
				nameIsError: false,
				nameIsSuccess: false,

				password: "",
				isShowPassword: false,
				passwordIsError: false,
				passwordIsSuccess: false,

				passwordConfirm: "",
				isShowPasswordConfirm: false,
				passwordConfirmIsError: false,
				passwordConfirmIsSuccess: false,

				code: "",
				codeIsError: false,
				codeIsSuccess: false,
				
				phone: "",
				phoneIsError: false,
				phoneIsSuccess: false,

				disable: true,
				
				iValue: '',
				
				getCodeText: '獲取驗證碼',
				
				pageKey: Math.random()
			};
		},
		activated() {
			this.name = ""
			this.nameIsError = false
			this.nameIsSuccess = false
			this.password = ""
			this.isShowPassword = false
			this.passwordIsError = false
			this.passwordIsSuccess = false
			this.passwordConfirm = ""
			this.isShowPasswordConfirm = false
			this.passwordConfirmIsError = false
			this.passwordConfirmIsSuccess = false
			this.code = ""
			this.codeIsError = false
			this.codeIsSuccess = false
			this.phone = ""
			this.phoneIsError = false
			this.phoneIsSuccess = false
			this.disable = true
			this.iValue = ''
			this.getCodeText = '獲取驗證碼'
			
			this.pageKey = Math.random()
			//document.getElementById("register_video").play()
		},
		computed: {
			...mapGetters(['config']),
		},
		methods: {
			...mapActions('user', ['intervalGetBalance', 'setBalance', 'clearIntervalGetBalance', 'setUserInfo', 'getUserInfo', 'getUnread']),
			linkcs(){
				window.open(this.config.service_link)
			},
			getCode () {
				this.phoneCensor()
				if (!this.phoneIsSuccess) {
					return
				}
				if (this.getCodeText != '獲取驗證碼') {
					return
				}
				let num = 60
				this.getCodeText = num + 's'
				let tim = setInterval((v) => {
					num = num - 1
					if (num == 0) {
						clearInterval(tim)
						this.getCodeText = '獲取驗證碼'
					} else {
						this.getCodeText = num  + 's'
					}
				}, 1000)
				http({
					method: 'post',
					url: '/sendSms',
					data: {
						phone: this.phone,
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message.success(res.message);
					} else {
						clearInterval(tim)
						this.getCodeText = '獲取驗證碼'
						this.$message.error(res.message);
					}
				})
			},
			nameCensor() {
				var regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
				this.nameIsError = !regex.test(this.name);
				var t = this.name.charAt(0).toLowerCase();
				if (t <= "z" && t >= "a") {
				} else {
					this.nameIsError = true;
				}
				this.nameIsSuccess = !this.nameIsError;

				this.allCensor();
			},
			nameChange() {
				this.nameIsError = false;
			},
			
			phoneCensor () {
				var regex = /^(\+852\s)?[123456789]{1}\d{7}$/;
				this.phoneIsError = !regex.test(this.phone);
				this.phoneIsSuccess = !this.phoneIsError;

				this.allCensor();
			},
			phoneChange () {
				this.phoneIsError = false;
			},

			passwordCensor() {
				var regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
				this.passwordIsError = !regex.test(this.password);

				this.passwordIsSuccess = !this.passwordIsError;

				this.allCensor();
			},
			passwordChange() {
				this.passwordIsError = false;
			},

			passwordConfirmCensor() {
				this.passwordConfirmIsError = !(this.passwordConfirm == this.password);
				if (!this.passwordIsSuccess) {
					this.passwordConfirmIsError = true;
				}
				this.passwordConfirmIsSuccess = !this.passwordConfirmIsError;

				this.allCensor();
			},
			passwordConfirmChange() {
				this.passwordConfirmIsError = false;
			},

			codeCensor() {
				if (this.code.length > 6 || this.code.length < 4 ) {
					this.codeIsError = true
				} else{
					this.codeIsError = false
				}
				// this.codeIsError = this.code.length < 6 && this.code.length > 4;
				this.codeIsSuccess = !this.codeIsError;

				this.allCensor();
			},
			codeChange() {
				this.codeIsError = false;
			},

			allCensor() {
				if (
					this.nameIsSuccess &&
					this.passwordIsSuccess &&
					this.passwordConfirmIsSuccess &&
					this.codeIsSuccess &&
					this.phoneIsSuccess
				) {
					this.disable = false;
				} else {
					this.disable = true;
				}
			},

			register() {
				if (this.disable) {
					this.nameCensor()
					this.passwordCensor()
					this.passwordConfirmCensor()
					this.codeCensor()
					this.phoneCensor()
					return false
				}
				let iText = ''
				if (this.iValue) {
					iText = this.iValue
				} else {
					iText = this.$route.query.i
				}
				http({
					method: 'post',
					url: '/register',
					data: {
						name: this.name,
						password: this.password,
						password_confirmation: this.passwordConfirm,
						phone: this.phone,
						captcha: this.code,
						i: localStorage.getItem('access_token_i') || iText,
						do_main: window.location.hostname
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$message.success('賬號註冊成功，前往登錄');
						// this.$router.push({
						// 	path: '/login'
						// })
						http({
							method: 'post',
							url: '/login',
							data: {
								// name: 'c1233215',
								// password: 'c123321',
								name: this.name,
								password: this.password,
							}
						}).then((res) => {
							if (res.code == 200) {
								// if (this.checkaccess) {
								// 	localStorage.setItem('access_name1', this.name)
								// 	localStorage.setItem('access_password1', this.password)
								// } else {
								// 	localStorage.removeItem('access_name1')
								// 	localStorage.removeItem('access_password1')
								// }
								this.$message.success('賬號登錄成功');
								localStorage.setItem('access_token', res.data.access_token)
								
								var userData = {
									...res.data,
									name: res.data.user ? res.data.user.name : ''
								}
								localStorage.setItem('userInfo', JSON.stringify(userData))
								localStorage.setItem('is_login_1', '1')
								// 获取钱包
								this.setBalance()
								this.intervalGetBalance()
								// 获取用户
								this.getUserInfo()
								this.setUserInfo(userData)
								this.getUnread()
								this.$router.push({
									path: '/'
								})
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						this.$message.error(res.message);
					}
				})
			},

			home() {
				this.$router.push({
					path: '/'
				})
			},
			linkLogin () {
				this.$router.push({
					path: '/login'
				})
			}
		},
	};
</script>

<style lang="less" scoped>
	.folbnts{
		width:404px;
		height: 64px;
		display:flex;
		margin-top: 20px;
		justify-content: space-between;
		.folbnt1 {
			width:128px;
			cursor: pointer;
			height:64px;
			border-radius: 12px;
			    background: rgba(255,255,255,.04);
				display:flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
			.imm{
				width:24px;
				height:24px;
			}
			&:nth-child(1){
				.imm{
					background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAKlBMVEX///9MaXH////////////////////////////////////////////////nuZ/FAAAADnRSTlNmAFcNM18GTRwmQS05EzOOwCUAAAAJcEhZcwAACxMAAAsTAQCanBgAAAHZSURBVHic1ZXpruMgDIUPi8Fs7/+6V2YJJk3bO5o/M0iV0oTPPhgvMH+48F8AgWuO1sbsOXwHQopQK6YvQCHclk0fgGaH2ZrYNeY6nFn3DujmySvhLnUT5Rnw9+3jLQHwT4B/8T692IO4gAIgb/NBPWatagFO9q+XnAmgfLnLALUbYAG7jNYV0iUkyNcTSAA5vZ/6hSyfji5RE7DbXJKNbEzT0j1AQQFpu1RsBWiJIiApIK6/xvBmZRdvF3EDAcA6QVHRqltTABAugBc+TPmn5zi9dcAD1Tx4yOrC6tzTgby1ircZj54U1/s0DUG7W1GaLrKKnVmyO2CBpr/AJudYkm5Xj5v0AlSaStrecsMY0zRwSNoE6cJhLSlr3+K+Stc4a4n1oVfIPq06Bb5cXF/BNXZnsUZ9cde9dz1FyqefIZYrFu5IDcFnQPjoY7uTpSP5ev0I7+Z2sjaOHgWMSrVneksie2Na1xLTFNKS1BBs65G2R8UVKVEpRNTjrE4QanKC9NoE5KcvcCB2fjEnMNRcjUAT1I/inhqZzp1bppS/b5VmWLr7CO+b8VRlV1D3dnrT7qd3GSjMzXFaA6V9GFljgOhF5csUvQ/FcPv+OHb9GLv1N2P36/oHgR9VZg8v7Do7gAAAAABJRU5ErkJggg==);
					background-size: cover;
					background-position: center;
				}
			}
			&:nth-child(2){
				.imm{
					background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAzUExURUdwTP///////////////////////////////////////////////////////////////8/RimEAAAARdFJOUwANLEAbM01mJllfORMHUwZG52DosAAAAU5JREFUeNrtVduShSAMk0uhBQX//2u3OMpIPWs5jzuzeWNMgJCAy5/BZsz2BRsoMko2c3yb4gWY4bvGTESHwun8zLTgmwFPMwq8bwR4gIqAZ83Dcumd72Ok23lW9u81B/k+BjF+IPCMYsWgWVjvYxMjvQoKC+QKWmpWnPL+XgsxY9CCqIkZY4xKA4Gj6i7WpJwqo1LvxgbpiFGBaYq0A7jWcmob0hUdZe4KIZ10u8xi9QB4eLeBIgWcFZoSGboXgxbwrNaB14L4PR0UPK+f2VYbe8UegMa+BOGKBCLVz2bp4NKeAYey++2X6jESXAZ94q66E/aDbWh0e2vqCHy4HaO1USDVZyXKNlw+hx2Wv4rgnYiH5xT+8thrsUspQPlsongfVEHmPaoC+YRJQX3zUGRdiBm+Ax+nZCPJGCVE1vIvWIvg6/cPc+hwsC7/mMYPjpMO7P0qHMYAAAAASUVORK5CYII=);
					background-size: cover;
					background-position: center;
				}
			}
			&:nth-child(3){
				.imm{
					background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwBAMAAAClLOS0AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAkUExURUdwTP///////////////////////////////////////////xR6XWcAAAAMdFJOUwBmDU5ZJxs8M18GRqV2IjkAAAETSURBVHja1ZKxTsMwFEVTS22jThwSWiKWFkGRYCESAyMusIOokIClICTWZoAZVpgC6p6f4P+QQyw7yfsAuIuf7vHztV8S/HH1Ps5Ov8dtv6sBokXTVymlRs2eG5Ln7ekdzOp+SPJu1j2Sesu93flYb1E6Kqoqj3zQZ+Z6/YtNuHB7Dj1w6foH+dAD6Yars00PsO5d0Et/8s+dJC8uzwcdWFblWxPYFN0EsY1uAnzQH5lHZMsW6HBeDOastUAIsYZXC1z4HGBVZkR2UiVQV3BcmIFiBxTyO1y1X47EnFdpeh04ZVu3gSg9lP2QExmYjylJ6Vhu2OFT9Ls5C8nfTVkJ9sERxGMBpJg/WARf8qN7D8G/1Q+Z8ydXbzZvygAAAABJRU5ErkJggg==);
					background-size: cover;
					background-position: center;
				}
			}
			.tese2{
				margin-top: 4px;
				    color: rgba(255,255,255,.6);
				    font-family: PingFang SC;
				    font-size: 12px;
				    font-style: normal;
				    font-weight: 400;
				    line-height: 20px;
			}
		}
	}
	.logboxbbox{
		padding: 12px;
		    width: 480px;
			margin:0 auto;
		    margin-top: 12px;
		    border-radius: 48px;
		    border: 2px solid rgba(51,88,148,.4);
		    background: -webkit-linear-gradient(304.19deg,rgba(67,111,185,.2) 3.59%,rgba(24,47,78,.2) 97.06%);
		    background: linear-gradient(145.81deg,rgba(67,111,185,.2) 3.59%,rgba(24,47,78,.2) 97.06%);
		    -webkit-backdrop-filter: blur(10px);
		    backdrop-filter: blur(10px);
		.logboxbboxt{
			width: 100%;
			    min-height: 470px;
			    background: -webkit-gradient(linear,left top,left bottom,from(rgba(98,153,237,.5)),color-stop(52.1%,rgba(31,59,107,.5)),to(rgba(34,59,103,.5)));
			    background: -webkit-linear-gradient(top,rgba(98,153,237,.5),rgba(31,59,107,.5) 52.1%,rgba(34,59,103,.5));
			    background: linear-gradient(180deg,rgba(98,153,237,.5),rgba(31,59,107,.5) 52.1%,rgba(34,59,103,.5));
			    -webkit-backdrop-filter: blur(30px);
			    backdrop-filter: blur(30px);
			    border-radius: 40px;
			    padding: 24px;
			    overflow: hidden;
		}
	}
  .register_box {
    text-align: center;
    overflow: auto;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    // background-image: url(../assets/login/back.jpg);
    background-image: url(/assets/backgroundlogiin.png);
    background-size: cover;
    background-position: center;
  }

	.video-img {
		position: fixed;
		left: 0px;
		top: 0px;
		bottom: 0px;
		right: 0px;
		width: 100%;
		height: 100%;
	}

	// .video {
	// 	position: fixed;
	// 	left: 0px;
	// 	top: 0px;
	// 	bottom: 0px;
	// 	object-fit: fill;
	// 	width: 100%;
	// 	height: 100%;
	// 	right: 0px;
	// }

	.login_box {
		// text-align: center;
		// overflow: auto;
		// z-index: 10;
		// top: 0;
		// left: 0;
		// right: 0;
		// bottom: 0;
		// position: fixed;

		.contentBox {
			    position: absolute;
			    left: 50%;
			    transform: translate(-50%,-50%);
			    top: 50%;

			.logo {
				margin: 0 auto 23px;
			}

			.sponsor {
				color: #fff;
				width: 404px;
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-flex-wrap: wrap;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				-webkit-box-pack: center;
				-webkit-justify-content: center;
				-ms-flex-pack: center;
				justify-content: center;
				// margin: -20px auto 30px auto;
.sponsor-img{
					    width: 100%;
						height:95px;
				}
				.title {
					font-size: 12px;
					height: 24px;
					display: -webkit-box;
					display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-webkit-align-items: center;
					-ms-flex-align: center;
					align-items: center;
					height: 20px;
					margin: 0px 12px;

					&:before {
						content: "";
						color: #fff;
						margin-right: 12px;
						background-image: -webkit-gradient(linear,
								left top,
								right top,
								from(rgba(255, 255, 255, 0)),
								to(#fff));
						background-image: -webkit-linear-gradient(left,
								rgba(255, 255, 255, 0),
								#fff);
						background-image: linear-gradient(to right,
								rgba(255, 255, 255, 0),
								#fff);
						display: inline-block;
						height: 1px;
						width: 132px;
					}

					&:after {
						content: "";
						color: #fff;
						margin-left: 12px;
						background-image: -webkit-gradient(linear,
								right top,
								left top,
								from(rgba(255, 255, 255, 0)),
								to(#fff));
						background-image: -webkit-linear-gradient(right,
								rgba(255, 255, 255, 0),
								#fff);
						background-image: linear-gradient(to left,
								rgba(255, 255, 255, 0),
								#fff);
						display: inline-block;
						height: 1px;
						width: 132px;
					}
				}

				.sponsorBox {
					margin-top: 15px;
					height: 45px;
					display: -webkit-box;
					display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;

					.sponsorItem {
						display: -webkit-box;
						display: -webkit-flex;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-webkit-align-items: center;
						-ms-flex-align: center;
						align-items: center;
						font-size: 12px;
						text-align: left;

						&:nth-child(1) {
							margin-right: 22px;
							margin-left: 8px;
						}

						&:nth-child(2) {
							margin-right: 8px;
						}

						img {
							height: 45px;
							margin-right: 6px;
						}

						.sponsorInfo {
							.sponsorInfoTop {
								margin-bottom: 5px;
							}

							.sponsorInfoBtm {
								-webkit-transform: scale(0.75);
								position: relative;
								left: -8px;
							}
						}
					}
				}
			}
		}

		.service {
			cursor: pointer;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			margin: 0 auto;
			-webkit-box-pack: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			justify-content: center;
			color: #fff;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			margin-top: 50px;

			img {
				margin-right: 5px;
			}

			a {
				cursor: pointer;
			}
		}
	}

	.login_input {
		margin: 0px auto;
		// width: 298px;
		text-align: center;

		.row {
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			width: 100%;
			// height: auto !important;
			// margin: 13px 0;
			border-radius: 5px;
			position: relative;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			width: 100%;
			// margin-top: 20px;
			height: 50px;

			.userWrap {
				width: 100%;
				    height: 48px;
				    border-radius: 12px;
				    display: -webkit-flex;
				    display: flex;
				    -webkit-align-items: center;
				    align-items: center;
				    padding: 0 16px;
				    background: rgba(121,129,164,.2);
				    border: 1px solid transparent;
				    -webkit-transition: all .3s;
				    transition: all .3s;

				.icon {
					display: block;
					width: 25px;
					height: 25px;
					position: relative;
					// left: 10px;
					// top: 7px;
					border-bottom-left-radius: 4px;
					border-top-left-radius: 4px;

					&.user {
						// background: url(../assets/login/icon_user.svg);
						background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAA5UExURUxpcf///////////////////////////////////////////////////////////////////////9URaEsAAAASdFJOUwBgEL8gcIDv30Cvn1Awz5Cgj8J0CjoAAAGnSURBVHja3VXduoQgCAxTAbOf5f0f9nzHbMHS3fvlKpVhBlCaftM4JEIR3FLk794uohijxX92f8nDPkECSscIRv5XeNohM3M4qG68+nLmcoi7yXQ9TsTmOoCtHM03xf6EzAM9GDqJ9VVB8V97WtdSinBjLvmt7yXECL5BYJvGYYPwLG0+4SHKlw7VRdQWxLpVIrg7gTf+tCQypP5Eq1ElqGdUWpH/d1kjovqzyTiJkNdKzOpRwKqClABsIKcatLJJFeWGGkXy+bWLSNJboXA4VWh1QCtLNpDwG7D1ALnJ2mTETU/R7ov0AA5LwVWHswArKdfvXe/gitp+Vkm3mvl/dPR1HJC9TltT1v1a5POZkojhnY6mrMG+KSZ5G4LGtJeJlXudxVh7Tdh2FIaDJlxBaVKLS/MUcAkAMWHzKNJiAM6b905wdQ5KOpXde/W3FZXdmUB7IRwNy1kFqNj+VNLuL/fdwsFjgvWhc0jhzoyz7pg2dhlAiqXKbyfUYOBnvH47gZlzWOoaDetjYHZs89PYQgOxA31IAg2Eopu+2hrShiJIKfD0k/YH+voqvciLmqQAAAAASUVORK5CYII=);
						background-size: contain;
						background-position: center;
					}

					&.user22 {
						// background: url(../assets/login/qian.png);
						background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAA5UExURUxpcf///////////////////////////////////////////////////////////////////////9URaEsAAAASdFJOUwBgEL8gcIDv30Cvn1Awz5Cgj8J0CjoAAAGnSURBVHja3VXduoQgCAxTAbOf5f0f9nzHbMHS3fvlKpVhBlCaftM4JEIR3FLk794uohijxX92f8nDPkECSscIRv5XeNohM3M4qG68+nLmcoi7yXQ9TsTmOoCtHM03xf6EzAM9GDqJ9VVB8V97WtdSinBjLvmt7yXECL5BYJvGYYPwLG0+4SHKlw7VRdQWxLpVIrg7gTf+tCQypP5Eq1ElqGdUWpH/d1kjovqzyTiJkNdKzOpRwKqClABsIKcatLJJFeWGGkXy+bWLSNJboXA4VWh1QCtLNpDwG7D1ALnJ2mTETU/R7ov0AA5LwVWHswArKdfvXe/gitp+Vkm3mvl/dPR1HJC9TltT1v1a5POZkojhnY6mrMG+KSZ5G4LGtJeJlXudxVh7Tdh2FIaDJlxBaVKLS/MUcAkAMWHzKNJiAM6b905wdQ5KOpXde/W3FZXdmUB7IRwNy1kFqNj+VNLuL/fdwsFjgvWhc0jhzoyz7pg2dhlAiqXKbyfUYOBnvH47gZlzWOoaDetjYHZs89PYQgOxA31IAg2Eopu+2hrShiJIKfD0k/YH+voqvciLmqQAAAAASUVORK5CYII=);
						background-size: contain;
						background-position: center;
					}

					&.password {
						// background: url(../assets/login/icon_password.svg);
						background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAzUExURUxpcf////////////////////////////////////////////////////////////////Hv/K4AAAAQdFJOUwBgcJB/v++fQN9QzyAQMLBQk0ruAAAA4UlEQVR42u2U2w7CMAhA6QVKL278/9dqTTRuZbgl6sPieVvLKdBmwDm5cKgkQhjKrvBI8iTHBm8oJAuyA5MoA3FHPAbHXEIdDD0e+fHN1TZ43I7SYdDJfdPDAnfvHFScmj70VacKtZ+l50VQaBtHlb7eNioi7enp0ZlS7AwKEzNrGXBo2SDd6M3VZPEiyC5+LszJABWBwYA/LvTHOiRkkfwXviHUQ4IXiYcEmCawBBoGhjI6aPU/oyWkVUDcM6XdevZIKqzisO/mtryVt/ghqYFWsM9iQB4GmkPaiMZ4gTNxBW+tJqIAT0niAAAAAElFTkSuQmCC);
						background-size: contain;
						background-position: center;
					}

					&.entryCode {
						// background: url(../assets/login/sign-icon-dun@2x.svg);
						background: url(/assets/login/phone.png);
						background-size: contain;
						background-position: center;
					}
				}

				input {
					display: block;
					width: 44px;
					height: 44px;
					line-height: 20px;
					padding: 10px 0;
					border-bottom-right-radius: 4px;
					border-top-right-radius: 4px;
					-webkit-box-flex: 1;
					-webkit-flex: 1;
					-ms-flex: 1;
					flex: 1;
					width: 390px;
					height: 100%;
					line-height: 100%;
					border: none;
					outline: none;
					padding: 10px 55px 10px 0;
					background-color: transparent;
					margin-left: 18px;
					font-size: 14px;
					color: #fff;
					opacity: 0.9;
					border-left: none !important;
					
					
					        /*input::placeholder{/* 去掉私有前缀，谷歌和火狐有效；IE和edge无效 */
					          /*  color: orchid;
					            opacity:1;
					            font-size: 15px;
					        }*/
				}
				input {
				    // -webkit-text-fill-color: #c1c3cb !important;  //颜色是设置成你需要的颜色
				}
				input::placeholder { /* Mozilla Firefox 19+ */
				            color: #c1c3cb;
				            opacity:1;
				        }
				input::-moz-placeholder { /* Mozilla Firefox 19+ */
				            color: #c1c3cb;
				            opacity:1;
				        }
				        /* 以上两个属性分别针对火狐浏览器不同版本 */
				
				       input::-ms-input-placeholder { /* IE */
				            color: #c1c3cb;
				            opacity:1; 
				        }
				
				        input::-webkit-input-placeholder{/* webkit内核的浏览器，如谷歌，edge */
				            color:#c1c3cb;
				            opacity:1;
				            font-size: 15px;
				        }
			}

			.has_error {
				border: 1px solid red;
			}

			.success-icon {
				    position: absolute;
				    right: 35px;
				    bottom: 19px;
				    width: 16px;
				    height: 16px;
			}

			.input_error {
				color: #fc5a5a;
				text-align: left;
				height: 20px;
				line-height: 20px;
				font-size: 12px;
				position: absolute;
				top: 41px;
				left: 4px;
				white-space: nowrap;

				top: 48px;
				color: rgb(252, 90, 90);
				display: flex;
				align-items: center;
				left: 0px;
			}
		}

		li {
			position: relative;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			width: 100%;
			margin-top: 20px;
		}

		.buttonBox {
					margin-top: 25px;
					position: relative;	
					width: 404px;
					    border-radius: 12px;
						height: 48px;
						overflow: hidden;
					&:after{ 
						content: "";
						    background: linear-gradient(90.6deg,#2160fe .81%,#94e4ff 10.04%,#249cfb 25.37%,#2057e2 48.25%,#249cfb 72.59%,#94e4ff 88.4%,#2160fe 99.78%);
		width: 404px;
						text-align: center;
						height: 48px;
						position: absolute;
						top: 0;
						left: 0;
					}
					button {
						font-size: 14px;
						width: 404px;
						text-align: center;
						height: 46px;
						line-height: 46px;
						color: #fff;
						border: none;
						cursor: pointer;
						position: relative;
						span {
							opacity: .6;	position: relative;
							z-index: 22;
							font-weight: 700;
							color: #fff;
							font-size: 18px;
						}
						&:after{
							content: "";
							    position: absolute;
							    top: 0;
							    left: 0;
							    width: 100%;
							    height: 100%;
							    background: linear-gradient(359.21deg,#1f59ff .74%,#218bff 27.83%,#4af4ff 99.37%);
							    z-index: 1;
						}
						&:before
							{
							    content: "";
							    position: absolute;
							    top: 0;
							    left: 0;
							    width: 100%;
							    height: calc(100% + 2px);
							    background-color: #000;
							    transition: all .3s;
							    opacity: 0.4;
							z-index: 2;
						}
						&.disable {
							cursor: not-allowed !important;
							opacity: 0.4;
						}
					}
				}

		.validImg {
			position: absolute;
			top: 10px;
			right: 18px;
			width: 110px;
			height: 30px;
			cursor: pointer;
		}

		.linkGroup {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			-webkit-flex-flow: row nowrap;
			-ms-flex-flow: row nowrap;
			flex-flow: row nowrap;
			-webkit-box-pack: justify;
			-webkit-justify-content: space-between;
			-ms-flex-pack: justify;
			justify-content: space-between;
			-webkit-box-align: center;
			-webkit-align-items: center;
			-ms-flex-align: center;
			align-items: center;
			margin-top: 15px;

			span {
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				font-size: 14px;
				color: #fff;
				cursor: pointer;

				span {
					color: #4080ff;
				}
			}
		}
	}
</style>
