<template>
	<div class="rightobbox inputnoright">

		<div class="title-bo2">
			<div class="left">
				<p>存款</p>
				<span>存款金额会存入中心钱包</span>
			</div>
			<div class="right">
				<!-- <div class="mes">
					存款教程
					<my-img srcText="mycenter/deposit/icon10.svg" />
				</div> -->
			</div>
		</div>

		<div class="types" v-if="showPay == 'ck'">
			<div class="title-f5">付款方式</div>
			<div class="type-list">
				<ul class="Checkbox" style="width: 920px; text-align: center; margin-left: -21px;">

					<div @click="getType(item)" :class="{'Active': item.payment_type == form.payment_type}"
						v-for="(item, index) in typeList" :key="index"
						style="width: 133px; height: 100px; margin: 0px 0px 20px 20px;">
						<li class="depositlist">
							<my-img srcText="mycenter/deposit/icon1.png" class="recommend" />
							<my-img v-if="item.payment_type == 11" srcText="mycenter/deposit/Alipay.png" styleText="height: 40px; margin-bottom: 10px;" />
							<my-img v-if="item.payment_type == 3"  srcText="mycenter/deposit/fps.png" styleText="height: 40px; margin-bottom: 10px;" />
							<my-img v-if="item.payment_type == 14"  srcText="mycenter/deposit/ELEVEN.png" styleText="height: 40px; margin-bottom: 10px;" />
							<my-img v-if="item.payment_type == 18"  srcText="mycenter/deposit/USTD.png" styleText="height: 40px; margin-bottom: 10px;" />
							<p v-if="item.payment_type == 3" style="line-height: 20px;">銀行卡 / 轉數快</p>
							<p v-else style="line-height: 20px;">{{item.pay_name}}</p>
						</li>
					</div>
				</ul>
			</div>

			<div v-if="!payShow" style="height: 300px;text-align: center;line-height: 300px;">
				<a-spin size="large" />
			</div>
			<div v-if="payShow">
				<div class="type3">
					<div class="title-f5" v-if="payType.length">{{form.payment_type == 3 ? '收款銀行' : '收款銀行'}}：</div>
					<div class="inputbo2" style="margin-top: -5px;" v-if="payType.length">
						<div style="display:flex;align-items: center;">
							<ul class="Checkbox"
								style="width: auto; text-align: center; margin-left: -21px; margin-top: 10px;">
								<div :class="{'Active': item.id == form.id}" @click="form.id = item.id" v-for="(item, index) in payType"
									:key="index" style="height: 40px;margin: 0px 0px 20px 20px;">
									<li class="payButtonSty1"><span>{{item.card_no}}</span><i></i></li>
								</div>
							</ul>
							<!-- <span class="tk_ts">
								<my-img srcText="mycenter/deposit/icon5.svg" />协议的区别
							</span> -->
						</div>
					</div>
					<div v-if="payType.length == 0">
						<a-alert :message="paymentName + ' 存款方式沒有通道，請選擇其他存款方式'" banner />
					</div>
				</div>

				<div class="type3" v-if="(form.payment_type == 3 || form.payment_type == 11 || form.payment_type == 14) && payType.length != 0">
					<div class="title-f5">存款人姓名</div>
					<div class="inputbo2">
						<input placeholder="請輸入存款人姓名" v-model="form.name" @input="formType3Change" type="text" style="width: 300px;">
						<span class="tk_ts">
							<my-img srcText="mycenter/deposit/icon5.svg" />
							為及時到賬，請務必輸入正確的存款人姓名
						</span>
					</div>
				</div>

				<!-- <div class="type3" v-if="form.payment_type == 3" style="margin-top: 20px;">
					<div class="title-f5">存款银行</div>
					<div class="inputbo2 selectcss">
						<a-select v-model="form.payment_desc" placeholder="请选择存款银行" @change="formType3Change" style="width: 300px;height:40px;">
							<a-select-option :value="item" v-for="(item, index) in banks" :key="index">
								{{item}}
							</a-select-option>
						</a-select>
						<span class="tk_ts">
							<my-img srcText="mycenter/deposit/icon5.svg" />
							为及时到账，请务必选择正确的存款银行
						</span>
					</div>
				</div> -->

				<div class="type3" style="margin-top: 20px;" v-if="payType.length">
					<div class="title-f5">存款金額</div>
					<ul class="Checkbox"
						style="width: 920px; text-align: center; margin-left: -21px; margin-top: 10px;">
						<div :class="{'Active': item == form.new_money}" @click="selectMoney(item)"
							v-for="(item, index) in moneyList" :key="index"
							style="width: 122px; height: 72px; margin: 0px 0px 20px 20px;">
							<li class="payButtonSty"><span>{{item}}</span><i></i></li>
						</div>
					</ul>
					<div class="inputbo2" style="margin-top: 0px;">
						<a-input-number class="ainputnumber" style="width: 286px;height: 50px;"
							placeholder="最低充值100元" v-model="form.new_money" @change="formType3Change" :min="0" size="large" />
						<span class="tk_ts">
							<my-img srcText="mycenter/deposit/icon5.svg" />請輸入充值金額
						</span>
					</div>
				</div>

				<div class="tk_jr" v-if="payType.length">
					<!--  -->
					<button class="Button  Button3" :class="{'disabled': !isPayBtn}"  @click="getPayDetail"
						style="width: 286px; height: 50px; margin-top: 20px;">{{ckBtnText}}</button>
				</div>
			</div>
		</div>
		
		<div class="pay_xnb_detail" v-if="showPay == 'yhk'">
			<div class="xnb_price_detail" style="margin-bottom: 30px;">
				<div>
					<p>匯款姓名</p>
					<span>{{payInfo.new_money}}</span>
				</div>
				<div>
					<p>匯款金額</p>
					<span>${{payInfo.new_money}}</span>
				</div>
			</div>
			<div class="xnb_tip_detail" style="margin-bottom: 40px;">
				請記錄以下收款信息，並匯款
			</div>
			<div class="xnb_ned_detail" style="margin-bottom: 30px;">
				<div class="xnb_ned_detail_div">
					<p>收款銀行</p>
					<span>{{payInfo.bank_name}}</span>
				</div>
				<div class="pay_bank_info_item_right" @click="copy(payInfo.bank_name)">
					復製
				</div>
			</div>
			<div class="xnb_ned_detail" style="margin-bottom: 30px;">
				<div class="xnb_ned_detail_div">
					<p>轉賬號碼</p>
					<span>{{payInfo.bank_no}}</span>
				</div>
				<div class="pay_bank_info_item_right" @click="copy(payInfo.bank_no)">
					復製
				</div>
			</div>
			<div class="xnb_ned_detail" style="margin-bottom: 30px;">
				<div class="xnb_ned_detail_div">
					<p>收款姓名</p>
					<span>{{payInfo.bank_username}}</span>
				</div>
				<div class="pay_bank_info_item_right" @click="copy(payInfo.bank_username)">
					復製
				</div>
			</div>
			<div class="xnb_ti_detail">
				注意：請充值 $ <span>{{payInfo.new_money}}</span> 。為了更好的用戶體驗，單據上載功能已經取消，充值時請加上小數點金額，如果沒有加上小數金額，將無法及時到賬。如有問題，請聯繫在線客服確認，謝謝！！
			</div>
			<div class="xnb_btns_detail">
				<a-button @click="initPay">
				    返回 重新輸入
				</a-button>
				<a-button @click="linkTransRecord" type="primary">確 定
				</a-button>
			</div>
		</div>
		<div class="pay_xnb_detail" v-if="showPay == 'zfb' || showPay == 'bld'">
			<div class="xnb_price_detail">
				<div>
					<p>匯款金額</p>
					<span>{{payInfo.new_money}}</span>
				</div>
			</div>
			<div class="xnb_tip_detail">
				請記錄以下收款信息，並匯款
			</div>
			<div class="xnb_img_detail">
				<p>收款銀行</p>
				<img :src="payInfo.bank_address" alt="">
			</div>
			<div class="xnb_ti_detail">
				注意：請充值 $ <span>{{payInfo.new_money}}</span> 。為了更好的用戶體驗，單據上載功能已經取消，充值時請加上小數點金額，如果沒有加上小數金額，將無法及時到賬。如有問題，請聯繫在線客服確認，謝謝！！
			</div>
			<div class="xnb_ned_detail">
				<div class="xnb_ned_detail_div">
					<p>幣種協議</p>
					<span>{{payInfo.bank_username}}</span>
				</div>
			</div>
			<div class="xnb_upd_detail" style="display: flex;">
				<p class="xnb_upd_name_detail" style="margin-right: 15px;">上載單據</p>
				<div>
					<uploadImage :limit="1" v-model="form.daohao_img"></uploadImage>
				</div>
			</div>
			<div class="xnb_btns_detail">
				<a-button @click="initPay">
				    返回 重新輸入
				</a-button>
				<a-button @click="linkTransRecord" type="primary">確 定
				</a-button>
			</div>
		</div>
		<div class="pay_xnb_detail" v-if="showPay == 'xnb'">
			<div class="xnb_price_detail">
				<div>
					<p>匯款金額</p>
					<span>${{payInfo.new_money}}</span>
				</div>
				<div>
					<p>汇款数量</p>
					<span>{{payInfo.digital_money}}</span>
				</div>
			</div>
			<div class="xnb_tip_detail">
				請記錄以下收款信息，並匯款
			</div>
			<div class="xnb_img_detail">
				<p>收款銀行</p>
				<img :src="payInfo.bank_address" alt="">
			</div>
			<div class="xnb_ti_detail">
				注意：當前支付的方式為<span> {{payInfo.bank_username}} </span>，支付數量為 <span>{{payInfo.digital_money}}</span>，如有手續費請加上手續費後支付，確保我司收到的數量為 <span>{{payInfo.digital_money}}</span>，否則無法及時到帳！
			</div>
			<div class="xnb_ned_detail">
				<div class="xnb_ned_detail_div">
					<p>轉賬號碼</p>
					<span>{{payInfo.bank_no}}</span>
				</div>
				<div class="pay_bank_info_item_right" @click="copy(payInfo.bank_no)">
					復製
				</div>
			</div>
			<div class="xnb_ned_detail">
				<div class="xnb_ned_detail_div">
					<p>幣種協議</p>
					<span>{{payInfo.bank_username}}</span>
				</div>
			</div>
			<div class="xnb_upd_detail" style="display: flex;">
				<p class="xnb_upd_name_detail" style="margin-right: 15px;">上載單據</p>
				<div>
					<uploadImage :limit="1" v-model="form.daohao_img"></uploadImage>
				</div>
			</div>
			<div class="xnb_btns_detail">
				<a-button @click="initPay">
				    返回 重新輸入
				</a-button>
				<a-button @click="linkTransRecord" type="primary">確 定
				</a-button>
			</div>
		</div>
		
		<div class="loading-app" v-if="showLoading">
			<div class="loading-box">
				<img src="/assets/loading.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import "swiper/swiper.min.css";
	import http from '@/http.js'
	import uploadImage from "@/components/uploadImage";
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: "Home",
		data() {
			return {
				tabidx: 1,

				typeList: [],

				paymentName: '',
				form: {
					payment_type: undefined,
					id: undefined,
					payment_desc: undefined,
					name: '',
					new_money: null,
					digital_money: '',
					daohao_img: '',
				},
				moneyList: [200, 500, 1000, 2000],

				payType: [],
				payShow: false,

				// 倒计时
				payTimeText: '',
				seconds: 60 * 30,
				
				// 展示充值信息
				showPay: 'ck',
				// yhk => 银行卡 ， xnb => 虚拟币， ck => 选择存款
				
				// 是否可点击充值
				isPayBtn: false,
				
				// 存款信息
				payInfo: {},
				
				// imgs: [{
				// 	img: require('../../../assets/mycenter/deposit/unionTran1.png'),
				// 	tips: ''
				// },{
				// 	img: require('../../../assets/mycenter/deposit/unionTran2.png'),
				// 	tips: ''
				// },{
				// 	img: require('../../../assets/mycenter/deposit/unionTran3.png'),
				// 	tips: ''
				// },{
				// 	img: require('../../../assets/mycenter/deposit/unionTran4.png'),
				// 	tips: ''
				// }],
				bannerSwiperOption: {
					speed: 300,
					loop: true,
					// 设置点击箭头
					navigation: {
						nextEl: ".swiper-button-next1",
						prevEl: ".swiper-button-prev1"
					},
					noSwiping : true,
					pagination: {
					    el: ".swiper-pagination",
						clickable: true,
					},
				},
				fileList: [],
				
				ckBtnText: '立即存款',
				
				showLoading: false
			};
		},
		components: {
			swiper,
			swiperSlide,
			uploadImage
		},
		computed: {
			...mapGetters(['banks']),
		},
		mounted() {
			http({
				method: 'post',
				url: '/member/paymentTypeList',
				data: {
					
				}
			}).then((res) => {
				if (res.code == 200) {
					this.typeList = res.data
					this.initGetPayType()
				} else {
					this.$message.error(res.message);
				}
			})
			
		},
		methods: {
			initGetPayType () {
				if (this.typeList.length) {
					this.form.payment_type = this.typeList[0].payment_type
					this.paymentName = this.typeList[0].pay_name
					this.getType({
						pay_name: this.paymentName,
						payment_type: this.form.payment_type
					})
				}
			},
			initPay () {
				this.form = {
					payment_type: this.typeList[0].payment_type,
					id: undefined,
					payment_desc: undefined,
					name: '',
					new_money: null,
					digital_money: '',
					daohao_img: '',
				}
				this.initGetPayType()
				this.showPay = 'ck'
			},
			linkTransRecord () {
				if (this.showLoading === true) {
					return 
				}
				
				if (this.form.payment_type != 3) {
					if (this.form.daohao_img == '') {
						this.$message.error('請先上傳單據');
						return
					}
				}
				
				this.showLoading = true
				
				http({
					method: 'post',
					url: '/member/confirmPayment',
					data: {
						payment_type: this.form.payment_type,
						id: this.form.id,
						// payment_desc: this.form.payment_desc,
						name: this.form.name,
						new_money: this.payInfo.new_money,
						digital_money: this.payInfo.digital_money,
						danhao_img: this.form.daohao_img,
					}
				}).then((res) => {
					this.showLoading = false
					if (res.code == 200) {
						this.$router.push({
							path: "/my-center/transRecord",
						});
						this.initPay()
					} else {
						this.$message.error(res.message);
					}
				})
			},
			showPayBldInfo () {
				this.showPay = 'bld'
			},
			showPayZfbInfo () {
				this.showPay = 'zfb'
			},
			showPayXnbInfo () {
				this.showPay = 'xnb'
			},
			showPayInfo () {
				this.showPay = 'yhk'
				// this.Time()
			},
			// 天 时 分 秒 格式化函数
			countDown() {
				let d = parseInt(this.seconds / (24 * 60 * 60))
				d = d < 10 ? "0" + d : d
				let h = parseInt(this.seconds / (60 * 60) % 24);
				h = h < 10 ? "0" + h : h
				let m = parseInt(this.seconds / 60 % 60);
				m = m < 10 ? "0" + m : m
				let s = parseInt(this.seconds % 60);
				s = s < 10 ? "0" + s : s
				this.payTimeText = m + '分' + s + '秒'
			},
			//定时器没过1秒参数减1
			Time() {
				setInterval(() => {
					this.seconds -= 1
					this.countDown()
				}, 1000)
			},
			copy(value) {
				//新建一个文本框
				let oInput = document.createElement('input');
				//赋值给文本框
				oInput.value = value;
				document.body.appendChild(oInput);
				// 选择对象;
				oInput.select();
				// 执行浏览器复制命令
				document.execCommand("Copy");
				//复制完成删除掉输入框
				oInput.remove()
				this.$message.success('復製成功');
			},
			selectMoney(money) {
				this.form.new_money = money
			},
			formNameChange (value) {
			},
			formType3Change () {
				if (this.form.payment_type == 3) {
					let isPayBtn = false
					if (this.form.name) {
						isPayBtn = true
					} else {
						isPayBtn = false
					}
					// if (this.form.payment_desc && isPayBtn) {
					// 	isPayBtn = true
					// } else {
					// 	isPayBtn = false
					// }
					if (this.form.new_money && isPayBtn) {
						isPayBtn = true
					} else {
						isPayBtn = false
					}
					this.isPayBtn = isPayBtn
				}
				if (this.form.payment_type == 11 || this.form.payment_type == 14) {
					let isPayBtn = false
					if (this.form.name) {
						isPayBtn = true
					} else {
						isPayBtn = false
					}
					
					this.isPayBtn = isPayBtn
				}
			},
			getPayDetail() {
				if (this.form.payment_type == 3) {
					if (!this.form.name) {
						this.$message.error('請輸入付款戶名');
						return
					}
					// if (!this.form.payment_desc) {
					// 	this.$message.error('请选择存款银行');
					// 	return
					// }
					if (!this.form.new_money) {
						this.$message.error('請輸入充值金額');
						return
					}
					this.$confirm({
						title: '溫馨提示',
						content: h => < div style ="font-size:16px;color:#333;margin-top:20px;margin-bottom:30px" > <a-icon style="color:red;margin-right:10px" type="exclamation-circle" / >公司銀行卡不定期更換， 每次充值請根據提交訂單， 生成的銀行卡轉賬， 切勿直接轉賬至之前轉入的銀行卡， 並且不要使用微信進行轉賬存款， 否則無法到賬， 概不負責！ < /div>,
						onOk: () => {
							this.getPayDetailOk()
						},
						width: '500px',
						cancelText: '取消',
						okText: '確定',
						onCancel: () => {

						},
						class: 'css1_models',
					});
				} else {
					this.getPayDetailOk()
				}
			},
			getPayDetailOk() {
				if (this.ckBtnText === '確認存款中...') {
					return
				}
				if (!this.form.new_money) {
					this.$message.error('請輸入充值金額');
					return
				}
				this.ckBtnText = '確認存款中...'
				http({
					method: 'post',
					url: '/member/paymentDetails',
					data: {
						payment_type: this.form.payment_type,
						id: this.form.id,
						// payment_desc: this.form.payment_desc,
						name: this.form.name,
						money: this.form.new_money
					}
				}).then((res) => {
					if (res.code == 200) {
						if (this.form.payment_type == 3) {
							this.showPayInfo()
						}
						if (this.form.payment_type == 18) {
							this.showPayXnbInfo()
						}
						if (this.form.payment_type == 11) {
							this.showPayZfbInfo()
						}
						if (this.form.payment_type == 14) {
							this.showPayBldInfo()
						}
						this.payInfo = res.data
					} else {
						this.$message.error(res.message);
					}
					this.ckBtnText = '立即存款'
				})
			},
			getType(paymentData) {
				this.payShow = false
				this.form.payment_type = paymentData.payment_type
				this.paymentName = paymentData.pay_name
				this.form = {
					...this.form,
					payment_desc: undefined,
					name: '',
					new_money: null,
					digital_money: '',
					daohao_img: '',
				}
				http({
					method: 'post',
					url: '/member/paymentInfo',
					data: {
						payment_type: this.form.payment_type
					}
				}).then((res) => {
					if (res.code == 200) {
						if (this.form.payment_type == 18) {
							this.isPayBtn = true
						} else {
							this.isPayBtn = false
						}
						this.payType = res.data
						if (this.payType.length) {
							this.form.id = this.payType[0].id
						}
					} else {
						this.$message.error(res.message);
					}
					this.payShow = true
				})
			}
		}
	};
</script>

<style lang="less" scoped>
.loading-app {
		background: rgba(0, 0, 0, .5);
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1300;
		overflow: hidden;
	}
	
	.loading-app div {
		height: 96px;
		width: 96px;
		position: relative;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	
	.loading-app div img {
		height: 96px;
		width: 96px;
		display: block;
	}
	
.depositlist {
	    height: 100%;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    flex-direction: column;
}
.pay_xnb_detail {
	width: 486px;
	margin: 0 auto;
			.xnb_price_detail {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 20px;
				div {
					width: 130px;
					
					p {
						text-align: center;
						color: #414755;
						    font-size: 14px;
						    margin-bottom: 6px;
							margin-bottom: 10px;
					}
					span {
						display: block;
						text-align: center;
						color: #3181ff;
						    font-size: 20px;
					}
				}
			}
			.xnb_tip_detail {
				    font-size: 20px;
				    margin-bottom: 24px;
				    text-align: center;
				    color: #a5a5a5;
				    font-weight: 400;
					text-align: center;
			}
			.xnb_img_detail {
				p {
					    display: block;
					    position: absolute;
					    color: #666666;
						    font-size: 14px;
				}
				img {
					width: 300px;
					display: block;
					margin: 0 auto;
					height: 300px;
				}
			}
			.xnb_ti_detail {
				    text-align: center;
				    color: red;
				    font-size: 18px;
				    margin-bottom: 15px;
					line-height: 24px;
				span {
					    color: #1f8ee6;
				}
			}
			.xnb_ned_detail {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom:20px;
				.xnb_ned_detail_div {
					display: flex;
					align-items: center;
					p {
						width: 70px;
						font-size: 14px;
						    color: #666666;
					}
					span {
						color: #9c9c9c;
						font-size: 14px;
						    position: relative;
					}
				}
				.pay_bank_info_item_right {
					background: none;
					padding: 0 4px;
					height: 20px;
					border: 1px solid #1f8ee6;
					border-radius: 3px;
					color: #1f8ee6;
					display: -webkit-box;
					display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-webkit-align-items: center;
					-ms-flex-align: center;
					align-items: center;
					height: 18px;
					font-size: 12px;
					border-radius: 9px;
					padding: 1px 7px;
					cursor: pointer;
				}
			}
			.xnb_upd_detail {
				.xnb_upd_name_detail {
					
				}
			}
			.xnb_btns_detail {
				margin-top: 50px;
				display: flex;
				align-items: center;
				justify-content: space-around;
				button {
					width: 225px;
					height: 50px;
				}
			}
		}
.swiperdom {
		height: 321px;
		img {
			    width: 80%;
			display: block;
			margin: 0 auto;
			height: 281px;
		}
	}
	.pay_detail {
		display: flex;

		.pay_left {
			display: inline-block;
			vertical-align: top;
			float: left;
			width: 385px;
			margin-right: 75px;

			.pay_title_1 {
				font-size: 16px;
				margin-top: 0;
				margin-bottom: 10px;
				color: #3a97e5 !important;
				display: flex;

				p {
					color: #333;
					font-weight: 700;
					font-size: 18px;
					margin-left: 4px;
				}
			}

			.pay_tips_1 {
				font-size: 14px;
				display: block;
				color: #3a97e5 !important;
				margin-bottom: 10px;
			}

			.pay_tips_2 {
				margin-bottom: 20px;

				p {
					font-size: 12px;
					color: #333;
					margin-bottom: 8px;
				}
			}

			.pay_card_1 {
				background: linear-gradient(120deg, #62c5ff 2%, #3ca4fa 100%);
				width: 385px;
				height: 235px;
				border-radius: 8px;

				.pay_bank_1 {
					height: 60px;
					display: -webkit-box;
					display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-webkit-align-items: center;
					-ms-flex-align: center;
					align-items: center;
					padding-right: 10px;

					img {
						width: 28px;
						height: 28px;
						margin: 0 13px 0 20px;
						-webkit-box-flex: 0;
						-webkit-flex: 0 0 auto;
						-ms-flex: 0 0 auto;
						flex: 0 0 auto;
					}

					p {
						font-style: normal;
						font-weight: normal;
						color: #fff;
					}
				}

				.pay_bank_info {
					.pay_bank_info_item {
						min-height: 26px;
						max-height: 28px;
						justify-content: space-between;
						font-size: 12px;
						display: -webkit-box;
						display: -webkit-flex;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-webkit-align-items: center;
						-ms-flex-align: center;
						align-items: center;
						padding-right: 10px;
						margin-bottom: 3px;

						.pay_bank_info_item_left {
							display: flex;
							align-items: center;

							p {
								font-size: 12px;
								width: 57px;
								text-align: right;
								margin-right: 10px;
								padding: 0;
								color: #fff;
							}

							span {
								color: #fff;
							}

							b {
								font-weight: 400;
								color: #fd2c2c !important;
							}
						}

						.pay_bank_info_item_right {
							background: none;
							padding: 0 4px;
							height: 20px;
							border: 1px solid #fff;
							border-radius: 3px;
							color: #fff;
							display: -webkit-box;
							display: -webkit-flex;
							display: -ms-flexbox;
							display: flex;
							-webkit-box-align: center;
							-webkit-align-items: center;
							-ms-flex-align: center;
							align-items: center;
							height: 18px;
							font-size: 12px;
							border-radius: 9px;
							padding: 1px 7px;
							cursor: pointer;
						}
					}
				}
			}
		}

		.pay_right {
			width: 435px;
			height: 464px;
			-webkit-box-shadow: 0 2px 5px 2px rgb(0 0 0 / 10%);
			box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 10%);
			margin-top: 30px;
			float: left;
			position: relative;

			.pay_swiper_icon {
				width: 60px;
				height: 60px;
				border-radius: 30px;
				-webkit-box-shadow: 0 0 5px 2px rgb(0 0 0 / 20%);
				box-shadow: 0 0 5px 2px rgb(0 0 0 / 20%);
				margin: auto;
				margin-top: -30px;
				display: inline-block;
				margin-left: 190px;
				background: #fff;
				// background-image: url(../../../assets/mycenter/deposit/help.5e2de32454a25f71a5e82bb8066314f7.png);
				background-image: url(/assets/mycenter/deposit/help.5e2de32454a25f71a5e82bb8066314f7.png);
				background-size: 50% 50%;
				background-position: center;
			}

			.tips_h3 {
				text-align: center;
				font-size: 16px;
				color: #333;
				margin-top: 20px;
				margin-bottom: 0;
			}
		}
	}

	.types {
		.title-f5 {
			-webkit-box-flex: 0;
			-webkit-flex: 0 0 70px;
			-ms-flex: 0 0 70px;
			flex: 0 0 70px;
			display: block;
			margin-right: 30px;
			font-size: 14px;
			color: #4b5e6f;
		}

		.type-list {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			margin-top: 10px;
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			min-height: 82px;
		}

		.Checkbox {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			font-size: 14px;

			&>div {
				border-radius: 6px;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				cursor: pointer;
				position: relative;
				border: solid 2px transparent;
				color: #333;

				&:before {
					position: absolute;
					left: -2px;
					right: -2px;
					bottom: -2px;
					top: -2px;
					display: block;
					border-radius: 6px;
					cursor: pointer;
					pointer-events: none !important;
					content: " ";
					border-style: solid;
					border-color: #d8dce7;
					border-width: 1px;
					color: #333;
				}
			}

			.recommend {
				width: 54px;
				position: absolute;
				left: -7px;
				height: 54px;
				position: absolute;
				left: -7px;
				top: -7px;
				background-size: contain;
				z-index: 1;
			}

			&>div:hover {
				border-width: 2px;
				border-color: transparent;
				border-image-source: linear-gradient(296deg, #3678fe 96%, #75a3ff);
				border-image-slice: 1;
				background-image: -webkit-linear-gradient(top, #ffffff, #ffffff), -webkit-linear-gradient(154deg, #3678fe 96%, #75a3ff);
				background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(296deg, #3678fe 96%, #75a3ff);
				background-origin: border-box;
				background-clip: content-box, border-box;
				-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
				box-shadow: 0 10px 20px 0 #dae8f2;

				&:before {
					border: none;
				}
			}

			.Active {
				border-width: 2px;
				border-color: transparent;
				border-image-source: linear-gradient(296deg, #3678fe 96%, #75a3ff);
				border-image-slice: 1;
				background-image: -webkit-linear-gradient(top, #ffffff, #ffffff), -webkit-linear-gradient(154deg, #3678fe 96%, #75a3ff);
				background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(296deg, #3678fe 96%, #75a3ff);
				background-origin: border-box;
				background-clip: content-box, border-box;
				-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
				box-shadow: 0 10px 20px 0 #dae8f2;

				&:before {
					border: none;
				}

				&:after {
					position: absolute;
					right: -1px;
					bottom: -1px;
					content: "";
					display: block;
					// background-image: url(https://static.odcqfh.com/main-consumer-web/assets-oss/kok/images/myCenter/xuanzhong.663fd6fb75dcf95527fb34e34ce58309.svg);
					background-size: cover;
					width: 24px;
					height: 24px;
					right: -2px !important;
					bottom: -2px !important;
					border: none;
				}
			}

			.act_percent {
				position: absolute;
				bottom: -10px;
				left: 50%;
				-webkit-transform: translateX(-50%);
				transform: translateX(-50%);
				min-width: 50px;
				height: 20px;
				line-height: 14px;
				padding: 4px 5px;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				color: #fff;
				font-size: 12px;
				-webkit-box-shadow: inset 0 1px 3px 0 rgba(159, 0, 0, .44);
				box-shadow: inset 0 1px 3px 0 rgba(159, 0, 0, .44);
				background-color: red;
				border-radius: 10px;
				white-space: nowrap;
			}
		}
	}

	.payButtonSty {
		width: 116px;
		height: 66px;
		border-radius: 6px;
		padding: 1px;
		background: transparent;
		line-height: 66px;
		position: relative;
		text-align: center;
		display: inline-block;
		margin: 0 20px 10px 0;
		color: #333;
		font-size: 18px;
		font-weight: 600;
		-webkit-box-sizing: content-box;
		box-sizing: content-box;
	}

	.payButtonSty1 {
		min-width: 99px;
		height: 38px;
		border-radius: 6px;
		padding: 1px;
		background: transparent;
		line-height: 36px;
		position: relative;
		text-align: center;
		display: inline-block;
		margin: 0 20px 10px 0;
		color: #333;
		font-size: 14px;
		font-weight: 600;
		box-sizing: content-box;
		margin-right: 0px;
		padding: 0 15px;
	}

	.mes {
		display: flex;
		align-items: center;
	}

	.inputbo2 {
		margin-top: 10px;

		input {
			width: 286px;
			height: 40px;
			border-radius: 6px;
			padding: 0 20px;
			border: solid 1px #d8dce7;
			color: #333;
			background-color: #fff;
			font-size: 12px;

			&:focus {
				-webkit-transition: background-color .2s ease, border .2s ease;
				transition: background-color .2s ease, border .2s ease;
				border: solid 1px #3678fe;
				-webkit-box-shadow: 0 10px 20px 0 #dae8f2;
				box-shadow: 0 10px 20px 0 #dae8f2;
			}
		}

		.tk_ts {

			margin-left: 20px;
			line-height: 40px;
			display: inline-block;
			font-size: 14px;
			color: #94a6b7;
			vertical-align: bottom;

			img {
				margin-right: 4px;
				width: 18px;
				vertical-align: sub;
			}
		}
	}

	.card {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;

		.mid {
			width: 440px;
			height: 140px;
			border-radius: 6px;
			-webkit-box-shadow: 0 10px 10px 0 rgba(208, 217, 225, .22);
			box-shadow: 0 10px 10px 0 rgba(208, 217, 225, .22);
			border: solid 1px #dfeaf6;
			background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#f9fcff));
			background-image: -webkit-linear-gradient(top, #ffffff, #f9fcff 100%);
			background-image: linear-gradient(to bottom, #ffffff, #f9fcff 100%);

			background-color: #fff;
			padding: 30px 0 20px 29px;
			line-height: 1;
			padding-top: 20px;
			padding-right: 31px;

			h5 {
				font-size: 16px;
				color: #333;
				font-weight: bold;

				span {
					font-size: 14px;
					color: #4080ff;
					cursor: pointer;

				}
			}

			.sub_tip {
				font-size: 14px;
				color: #94a6b7;
				line-height: 1;
				margin-top: 10px;
				width: 226px;
			}

			.trades {
				margin-top: 10px;
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: start;
				-webkit-justify-content: flex-start;
				-ms-flex-pack: start;
				justify-content: flex-start;
				-webkit-box-align: start;
				-webkit-align-items: flex-start;
				-ms-flex-align: start;
				align-items: flex-start;
				-webkit-box-orient: horizontal;
				-webkit-box-direction: normal;
				-webkit-flex-flow: row nowrap;
				-ms-flex-flow: row nowrap;
				flex-flow: row nowrap;

				li {
					width: 80px;
					height: 76px;
					margin-right: 20px;
					display: -webkit-box;
					display: -webkit-flex;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-orient: vertical;
					-webkit-box-direction: normal;
					-webkit-flex-direction: column;
					-ms-flex-direction: column;
					flex-direction: column;
					-webkit-box-pack: start;
					-webkit-justify-content: flex-start;
					-ms-flex-pack: start;
					justify-content: flex-start;
					-webkit-box-align: center;
					-webkit-align-items: center;
					-ms-flex-align: center;
					align-items: center;
					border-radius: 6px;
					-webkit-box-shadow: 0 10px 10px 0 rgba(208, 217, 225, .22);
					box-shadow: 0 10px 10px 0 rgba(208, 217, 225, .22);
					border: solid 1px #dfeaf6;
					cursor: pointer;
					background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#f9fcff));
					background-image: -webkit-linear-gradient(top, #ffffff, #f9fcff 100%);
					background-image: linear-gradient(to bottom, #ffffff, #f9fcff 100%);

					img {
						display: block;
						width: 34px;
						height: 34px;
						margin: 10px auto 8px;
					}

					span {
						color: #4b5e6f;
						font-size: 14px;
					}
				}
			}

			.arrow {
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: start;
				-webkit-justify-content: flex-start;
				-ms-flex-pack: start;
				justify-content: flex-start;
				margin-top: 32px;

				li {
					font-size: 12px;
					color: #333;
					-webkit-box-flex: 0;
					-webkit-flex: none;
					-ms-flex: none;
					flex: none;
					position: relative;
					text-align: center;
					height: 28px;
					line-height: 28px;

					&:nth-child(1) {
						border-top-left-radius: 4px;
						border-bottom-left-radius: 4px;
						width: 132px;
						// background-image: url(https://static.odcqfh.com/main-consumer-web/assets-oss/commons/images/deposit/usdt/usdt-process-1.17ad1e88ac6f7c26e958ed9dcfc7b6d2.svg);
						background-repeat: no-repeat;
						background-position: top center;
						background-size: contain;
					}

					&:nth-child(2) {
						border-top-left-radius: 4px;
						border-bottom-left-radius: 4px;
						width: 168px;
						// background-image: url(https://static.odcqfh.com/main-consumer-web/assets-oss/commons/images/deposit/usdt/usdt-process-2.d6ddac09f44e5cd307dde4bfb0ed6818.svg);
						background-repeat: no-repeat;
						background-position: top center;
						background-size: contain;
					}

					&:nth-child(3) {
						border-top-right-radius: 4px;
						border-bottom-right-radius: 4px;
						width: 78px;
						// background-image: url(https://static.odcqfh.com/main-consumer-web/assets-oss/commons/images/deposit/usdt/usdt-process-3.52ca2a656bd864df433bfcc97fd8eb79.svg);
						background-repeat: no-repeat;
						background-position: top center;
						background-size: contain;
					}
				}
			}
		}
	}
</style>
