<template>
	<div class="rightobbox">

		<div class="title-bo2">
			<div class="left">
				<p>投註記錄
				</p>
				<span>
					每個場館的數據將有一定時間的延遲，僅供參考使用
				</span>
			</div>
		</div>

		<div class="serach-data">
			<div class="serach-item">
				<p style="margin-left: 0px;">選擇遊戲：</p>
				<a-select v-model="api_type" :key="config.plat_type.length" style="width: 257px" placeholder="請選擇遊戲" @change="getTableData">
					<a-select-option value="全部">
						全部
					</a-select-option>
					<a-select-option v-for="(item, index) in config.plat_type" :key="index" :value="item.api_name">
						{{item.api_title}}
					</a-select-option>
				</a-select>
			</div>
			<!-- <div class="serach-item">
				<p style="margin-left: 0px;">投注状态：</p>
				<a-select default-value="lucy" style="width: 240px" @change="handleChange">
					<a-select-option value="jack">
						今日
					</a-select-option>
					<a-select-option value="lucy">
						近7日
					</a-select-option>
					<a-select-option value="disabled" disabled>
						近15日
					</a-select-option>
					<a-select-option value="Yiminghe">
						本月
					</a-select-option>
				</a-select>
			</div> -->
		</div>
		<div class="serach-data">
			<div class="serach-item">
				<p style="margin-left: 0px;">投註時間：</p>
				<a-radio-group v-model="selectDate" style="width: 280px" @change="onChangeDate">
					<a-radio-button value="0">
						今日
					</a-radio-button>
					<a-radio-button value="7">
						近7日
					</a-radio-button>
					<a-radio-button value="15">
						近15日
					</a-radio-button>
					<a-radio-button value="30">
						本月
					</a-radio-button>
				</a-radio-group>
			</div>
			<div class="serach-item" style="width: 420px;">
				<p style="margin-left: 0px;">时间筛选：</p>
				<a-range-picker  :locale="DateLocale" :showTime="true" style="width: 400px;" format="YYYY-MM-DD HH:mm:ss" @ok="onChangeDate1" v-model="selectDateValue" :placeholder="['開始時間', '結束時間']"/>
			</div>
			<!-- <button data-label="交易记录-查询" class="Button333" style="width: 113px; height: 40px;margin-left: 20px;">查询</button> -->
		</div>

		<a-table rowKey="betTime" :columns="columns" :locale="locale" :data-source="data" :pagination="false" :loading="tableLoading">
			<a slot="name" slot-scope="text">{{ text }}</a>
			<div slot="flag_status" slot-scope="flag">
				<span v-if="flag == 1" style="color:#4CAF50;font-weight: 700;">已結算</span>
				<span v-if="flag == 2" style="color:#FF9800;font-weight: 700;">未結算</span>
				<span v-if="flag == 3" style="color:red;font-weight: 700;">無效</span>
			</div>
			<div slot="netAmount" slot-scope="netAmount">
				<span v-if="netAmount < 0" style="color:red">{{netAmount}}</span>
				<span v-else style="color:#4CAF50">{{netAmount}}</span>
			</div>
		</a-table>
		<div class="fotecs" v-if="data.length">
			<div>有效投注：<span>{{all_valid_bet_amount}}</span></div>
			<div>输赢：<span>{{all_net_amount}}</span></div>
		</div>
		<div v-if="data.length" style="text-align: right;margin-top: 20px;">
			<a-pagination v-model="tablePage" :total="tableTotal" show-less-items @change="tablePageChange" />
		</div>
	</div>
</template>

<script>
	const columns = [{
			title: '投註時間',
			dataIndex: 'betTime',
			key: 'betTime',
		},
		{
			title: '訂單號碼',
			dataIndex: 'billNo',
			key: 'billNo',
		},
    {
      title: '遊戲平臺',
      dataIndex: 'api_type',
      key: 'api_type',
      ellipsis: true,
    },
		{
			title: '遊戲名稱',
			dataIndex: 'gameCode',
			key: 'gameCode',
			ellipsis: true,
		},

		{
			title: '投註金額',
			dataIndex: 'betAmount',
			key: 'betAmount',
			ellipsis: true,
		},
		{
			title: '有效投註',
			dataIndex: 'validBetAmount',
			key: 'validBetAmount',
			ellipsis: true,
		},
		{
			title: '輸贏金額',
			dataIndex: 'netAmount',
			key: 'netAmount',
			ellipsis: true,
			scopedSlots: {
				customRender: 'netAmount'
			},
		},

		{
			title: '交易狀態',
			dataIndex: 'flag',
			key: 'flag',
			ellipsis: true,
			scopedSlots: {
				customRender: 'flag_status'
			},
		}
	];
	
	import http from '@/http.js'
	import Empty from '@/components/Empty.vue'
	import moment from 'moment'
	function getDay(day, type) {
		var today = new Date();
		var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
		today.setTime(targetday_milliseconds); //注意，这行是关键代码
		var tYear = today.getFullYear();
		var tMonth = today.getMonth();
		var tDate = today.getDate();
		tMonth = doHandleMonth(tMonth + 1);
		tDate = doHandleMonth(tDate);
		if (type == 'start') {
			return tYear + "-" + tMonth + "-" + tDate + ' 00:00:00';
		} else if (type == 'end') {
			return tYear + "-" + tMonth + "-" + tDate + ' 23:59:59';
		}
	}
	
	function doHandleMonth(month) {
		var m = month;
		if (month.toString().length == 1) {
			m = "0" + month;
		}
		return m;
	}
	
	function getLastDay(){
	    var y = new Date().getFullYear(); //获取年份
	    var m = new Date().getMonth() + 1; //获取月份
	    var d = '01'
	    m = m < 10 ? '0' + m : m; //月份补 0
	    return [y,m,d].join("-") + ' 00:00:00'
	}
	import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
	import 'moment/locale/zh-cn';
	
	import {
		mapGetters
	} from 'vuex';
	
	export default {
		name: "Home",

		data() {
			return {
				DateLocale: locale,
				selectDate: '0',
				data: [],
				columns,
				locale: {
					emptyText: < Empty 
					msg1 = "暫無投註記錄" / >
				},
				start: getDay(0, 'start'),
				end: getDay(0, 'end'),
				api_type: '全部',
				
				gameList: [],
				
				selectDateValue: [
					moment(getDay(0, 'start'), 'YYYY-MM-DD HH:mm:ss'),
					moment(getDay(0, 'end'), 'YYYY-MM-DD HH:mm:ss')
				],
				
				tablePage: 1,
				tableTotal: 0,
				tableLoading: true,
				
				all_net_amount: 0,
				all_valid_bet_amount: 0,
				
			};
		},
		
		computed: {
			...mapGetters(['config']),
		},
		
		mounted() {
			// http({
			// 	method: 'POST',
			// 	url: '/member/apiList',
			// 	data: {
			// 	}
			// }).then((res) => {
			// 	if (res.code == 200) {
			// 		this.gameList = res.data
			// 		// if(this.gameList.length) {
			// 		// 	this.api_type = this.gameList[0].api_name
			// 		// }
			// 	} else {
			// 		this.$message.error(res.message);
			// 	}
			// })
			this.getTableData()
		},
		methods: {
			tablePageChange (index) {
				this.tablePage = index
				this.getTableData()
			},
			onChangeDate1 (date) {
				this.start = moment(date[0],'YYYY-MM-DD HH:mm:ss')
				this.end = moment(date[1],'YYYY-MM-DD HH:mm:ss')
				this.selectDate = undefined
				this.selectDateValue = [this.start, this.end]
				this.getTableData()
			},
			onChangeDate () {
				if (this.selectDate == 0) {
					this.start = moment(getDay(0, 'start'), 'YYYY-MM-DD HH:mm:ss')
					this.end = moment(getDay(0, 'end'), 'YYYY-MM-DD HH:mm:ss')
				}
				if (this.selectDate == 7) {
					this.start = moment(getDay(-7, 'start'), 'YYYY-MM-DD HH:mm:ss')
					this.end = moment(getDay(0, 'end'), 'YYYY-MM-DD HH:mm:ss')
				}
				if (this.selectDate == 15) {
					this.start = moment(getDay(-15, 'start'), 'YYYY-MM-DD HH:mm:ss')
					this.end = moment(getDay(0, 'end'), 'YYYY-MM-DD HH:mm:ss')
				}
				if (this.selectDate == 30) {
					this.start = moment(getLastDay(), 'YYYY-MM-DD HH:mm:ss')
					this.end = moment(getDay(0, 'end'), 'YYYY-MM-DD HH:mm:ss')
				}
				
				this.selectDateValue = [this.start, this.end]
				this.getTableData()
			},
			getTableData () {
				this.tableLoading = true
				let api_type_text = this.api_type
				if (this.api_type == "全部") {
					api_type_text = undefined
				}
				http({
					method: 'post',
					url: '/member/gameRecord',
					data: {
						page: this.tablePage,
						start: moment(this.start,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
						end: moment(this.end,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
						api_type: api_type_text
					}
				}).then((res) => {
					if (res.code == 200) {
						this.data = res.data.data
						this.tableTotal = res.data.total
						
						this.all_net_amount = res.data.all_net_amount
						this.all_valid_bet_amount = res.data.all_valid_bet_amount
					} else {
						this.$message.error(res.message);
					}
					this.tableLoading = false
				})
			}
		}
	};
</script>

<style lang="less" scoped>
.fotecs {
	text-align: right;
	background: #fafafa;
	color: rgb(51, 51, 51) !important;
	    font-size: 14px !important;
	height: 54px;
	line-height: 54px;
	padding-right:20px;
	font-weight: 700;
	display: flex;
	justify-content: center;
	div {
		span {
			// margin-left:4px;
			color:red;
		}
	}
	div:nth-child(1) {
		margin-right: 70px;
	}
}
	.serach-data {
		display: flex;
		margin-bottom: 20px;
	}

	.serach-item {
		display: flex;
		width: 380px;

		p {
			width: 70px;
			min-width: 70px;
			margin: 0 0 0 33px;
			color: rgb(75, 94, 111);
			font-size: 14px;
			line-height: 40px;
		}
	}

	.Button333 {
		border: none;
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
		background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
		color: #fff;
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		transition: all .3s ease;
		font-size: 16px;
		border-radius: 6px;
		cursor: pointer;

		&:hover {
			background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
			background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
			background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
			-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
			box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
		}
	}
</style>
