<template>
	<div class="rightobbox">

		<div class="title-bo2">
			<div class="left">
				<p>活動申請
				</p>
				<span>
				
				</span>
			</div>
		</div>
		
		<div class="listd" v-loading="tableLoading">
			<div class="itemd" v-for="(item, index) in list">
				<div class="title">
					<div class="text">
						{{item.title}}
					</div>
					<span class="time">{{item.created_at}}</span>
				</div>
				<p v-if="item.status == 1" style="color: #FF9800;">你申请的活动正在 审核中，请耐心等待！</p>
				<p v-if="item.status == 2">恭喜妳，妳的活動申請已審核通過，贈送金額已發放到妳的紅利賬戶，請查收！</p>
				<p v-if="item.status == 3" style="color: red;">妳的活動申請未通過，原因：{{item.fail_reason}}</p>
			</div>
		</div>
		
		<div v-if="list.length" style="text-align: right;margin-top: 20px;">
			<a-pagination v-model="tablePage" :total="tableTotal" show-less-items @change="tablePageChange" />
		</div>
	</div>
</template>

<script>
	import http from '@/http.js'
	import Empty from '@/components/Empty.vue'
	import moment from 'moment'
	
	import {
		mapGetters
	} from 'vuex';
	
	export default {
		name: "Home",

		data() {
			return {
				list: [],
				tablePage: 1,
				tableTotal: 0,
				
				tableLoading: false
			};
		},
		activated() {
			this.getTableData()
		},
		mounted() {
			this.getTableData()
		},
		methods: {
			tablePageChange (index) {
				this.tablePage = index
				this.getTableData()
			},
			getTableData () {
				this.tableLoading = true
				let api_type_text = this.api_type
				if (this.api_type == "全部") {
					api_type_text = undefined
				}
				http({
					method: 'post',
					url: '/member/myActivity',
					data: {
						page: this.tablePage,
					}
				}).then((res) => {
					if (res.code == 200) {
						this.list = res.data.list
						this.tableTotal = res.data.meta.pagination.total
					} else {
						this.$message.error(res.message);
					}
					this.tableLoading = false
				})
			}
		}
	};
</script>

<style lang="less" scoped>
	.listd {
			.itemd {
				margin-bottom: 20px;
				width:100%;
				padding: 12px 20px;
				    background: #f0f7ff;
				border: 1px solid #e8e8e8;
				border-radius: 8px;
				.title {
					display:flex;
					justify-content: space-between;
					
					.text {
						width: 85%;
						font-size: 16px;
						line-height: 20px;
					}
					.time {
						line-height: 20px;
						
						font-size: 14px;
					}
				}
				p {
					margin-top: 8px;
				}
			}
		}
	.serach-data {
		display: flex;
		margin-bottom: 20px;
	}

	.serach-item {
		display: flex;
		width: 380px;

		p {
			width: 70px;
			min-width: 70px;
			margin: 0 0 0 33px;
			color: rgb(75, 94, 111);
			font-size: 14px;
			line-height: 40px;
		}
	}

	.Button333 {
		border: none;
		background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #5fabff), to(#3678fe));
		background-image: -webkit-linear-gradient(left, #5fabff 1%, #3678fe);
		background-image: linear-gradient(to right, #5fabff 1%, #3678fe);
		color: #fff;
		-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .2);
		transition: all .3s ease;
		font-size: 16px;
		border-radius: 6px;
		cursor: pointer;

		&:hover {
			background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, #4c87fe), to(#3678fe));
			background-image: -webkit-linear-gradient(left, #4c87fe 1%, #3678fe);
			background-image: linear-gradient(to right, #4c87fe 1%, #3678fe);
			-webkit-box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
			box-shadow: 0 10px 20px 0 rgba(60, 142, 254, .4);
		}
	}
</style>
